import styled from '@emotion/styled';
import {
  DASHBOARD_NAV_HEADER_HEIGHT,
  SIDE_NAV_WIDTH,
} from '@layouts/dashboard/constants';
import CircularProgress from '@mui/material/CircularProgress';
import { KargoDrawer } from '../drawer';
import { KargoHeaderLoadingSkeleton } from './header-loading-skeleton';
import { KargoNavLoadingSkeleton } from './nav-loading-skeleton';

const StyledKargoFullPageLoadingSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledKargoContentContainer = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - ${DASHBOARD_NAV_HEADER_HEIGHT});
  width: calc(100% - ${SIDE_NAV_WIDTH});
  margin-left: ${SIDE_NAV_WIDTH};
  background-color: ${(p) => p.theme.colors.backgroundPrimary};
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(p) => p.theme.colors.gray400};
`;

const KargoFullPageLoadingSkeleton = (): JSX.Element => {
  return (
    <StyledKargoFullPageLoadingSkeletonContainer>
      <KargoHeaderLoadingSkeleton />

      <StyledKargoContentContainer>
        <StyledLoadingContainer>
          <StyledCircularProgress size={50} />
        </StyledLoadingContainer>
      </StyledKargoContentContainer>

      <KargoDrawer variant='persistent' anchor='left' open>
        <KargoNavLoadingSkeleton />
      </KargoDrawer>
    </StyledKargoFullPageLoadingSkeletonContainer>
  );
};

export { KargoFullPageLoadingSkeleton };
