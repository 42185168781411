import styled from '@emotion/styled';
import {
  DASHBOARD_NAV_HEADER_HEIGHT,
  SIDE_NAV_WIDTH,
} from '@layouts/dashboard/constants';
import { Skeleton } from '@mui/material';

const StyledSkeletonHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  width: calc(100% - ${SIDE_NAV_WIDTH} + 55px);
  margin-left: calc(${SIDE_NAV_WIDTH} - 55px);
  padding: 8px 24px;
  border-bottom: 1px solid ${(p) => p.theme.colors.borderPrimary};
`;

const StyledSkeletonHeaderMain = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledSkeletonHeaderFacility = styled(Skeleton)`
  width: 300px;
  height: 32px;
  margin-left: 50px;
`;

const StyledSkeletonHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const KargoHeaderLoadingSkeleton = (): JSX.Element => {
  return (
    <StyledSkeletonHeaderContainer>
      <StyledSkeletonHeaderMain>
        <StyledSkeletonHeaderFacility />
      </StyledSkeletonHeaderMain>

      <StyledSkeletonHeaderActions>
        <Skeleton variant='circular' width={34} height={34} />
        <Skeleton variant='circular' width={34} height={34} />
      </StyledSkeletonHeaderActions>
    </StyledSkeletonHeaderContainer>
  );
};

export { KargoHeaderLoadingSkeleton };
