import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import generatedIntrospection from 'generated/graphql';
import { createClient } from 'graphql-ws';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Auth0TokenContext } from '../auth0-token';
import { useLogger } from '../logging';

const API_URI =
  process.env.NEXT_PUBLIC_GQL_ENDPOINT || 'https://api.kargo.zone/graphql';
const WSS_URI =
  process.env.NEXT_PUBLIC_WSS_ENDPOINT || 'wss://api.kargo.zone/graphql';

type Props = {
  isDevice?: boolean;
  children: React.ReactNode;
};

const ApolloClientProvider = ({ isDevice, children }: Props): JSX.Element => {
  const { token } = useContext(Auth0TokenContext);
  const logger = useLogger();

  const [deviceInfo, setDeviceInfo] = useState<{
    token: string;
    signature: string;
  } | null>(null);

  useEffect(() => {
    if (!isDevice) {
      return;
    }

    (async function getDeviceToken() {
      try {
        const response = await fetch('http://localhost:20050');
        const deviceAuthToken = response.headers.get('device_auth');
        const deviceAuthSig = response.headers.get('device_auth_sig');

        if (deviceAuthToken && deviceAuthSig) {
          setDeviceInfo({
            token: deviceAuthToken,
            signature: deviceAuthSig,
          });
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [isDevice]);

  const apolloClient = useMemo(() => {
    // https://www.apollographql.com/docs/react/data/subscriptions/#websocket-setup
    const uploadLink = createUploadLink({ uri: API_URI });

    const wsLink =
      typeof window !== 'undefined'
        ? new GraphQLWsLink(
            createClient({
              url: WSS_URI,
              retryAttempts: 10,
              connectionParams: {
                Authorization: token ? `Bearer ${token}` : '',
                ...(deviceInfo
                  ? {
                      device_auth: deviceInfo.token,
                      device_auth_sig: deviceInfo.signature,
                    }
                  : {}),
              },
            }),
          )
        : null;

    const splitLink =
      typeof window !== 'undefined' && wsLink
        ? split(
            ({ query }) => {
              const definition = getMainDefinition(query);

              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              );
            },
            wsLink,
            uploadLink,
          )
        : uploadLink;

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          logger.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );

      if (networkError) {
        logger.error(`[Network error]: ${networkError}`);
      }
    });

    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          ...(deviceInfo
            ? {
                device_auth: deviceInfo.token,
                device_auth_sig: deviceInfo.signature,
              }
            : {}),
        },
      });

      return forward(operation);
    });

    return new ApolloClient({
      cache: new InMemoryCache({
        possibleTypes: generatedIntrospection.possibleTypes,
        typePolicies: {
          Query: {
            fields: {
              userNotifications: {
                keyArgs: ['facilityId', 'types', 'isRead'],
                // Apollo does not have good documentation or solutions for setting proper type on args
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                merge(existing = [], incoming, { args: { offset = 0 } }: any) {
                  const merged = existing ? existing.slice(0) : [];

                  for (let i = 0; i < incoming.length; ++i) {
                    merged[offset + i] = incoming[i];
                  }
                  return merged;
                },
              },
            },
          },
        },
      }),
      link: from([errorLink, authLink, splitLink]),
      connectToDevTools: process.env.NODE_ENV === 'development',
    });
  }, [token, logger, deviceInfo]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { ApolloClientProvider };
