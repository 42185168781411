import { Logo } from '@components/logo';
import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

const StyledSkeletonNavContainer = styled.div`
  width: 225px;
  min-height: 100vh;
  color: ${(p) => p.theme.colors.contentPrimary};
  background-color: ${(p) => p.theme.colors.sidebarBackground};
  overflow: auto;
`;

const StyledSkeletonNavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.black};
  height: 60px;
`;

const StyledKargoLogo = styled.span`
  margin-left: 26px;
  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledSkeletonNavList = styled.div`
  margin-top: 8px;
`;

const StyledSkeletonNavItem = styled(Skeleton)`
  margin-left: 26px;
  margin-bottom: 14px;
  height: 30px;
  width: 172px;
  background-color: ${(p) => p.theme.colors.gray600};
`;

const KargoNavLoadingSkeleton = (): JSX.Element => {
  return (
    <StyledSkeletonNavContainer>
      <StyledSkeletonNavLogoContainer>
        <StyledKargoLogo>
          <Logo />
        </StyledKargoLogo>
      </StyledSkeletonNavLogoContainer>

      <StyledSkeletonNavList>
        {Array.from(Array(10)).map((_, idx) => (
          <StyledSkeletonNavItem key={idx} />
        ))}
      </StyledSkeletonNavList>
    </StyledSkeletonNavContainer>
  );
};

export { KargoNavLoadingSkeleton };
